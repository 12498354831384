<template>
  <b-row>
    <b-col md="6">
      <!-- annee -->
      <b-form-group
        label-cols-md="3"
        label="Année"
      >
        <validation-provider
          v-slot="{ errors }"
          rules="required"
        >
          <v-select
            v-model="infos.annee"
            type="text"
            :options="annees"
            :state="errors.length > 0 ? false : null"
            placeholder="Renseignez l'année en cours"
            required="true"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

    </b-col>
    <b-col md="6">
      <!-- mois -->
      <b-form-group
        label-cols-md="3"
        label="Mois"
      >
        <validation-provider
          v-slot="{ errors }"
          rules="required"
        >
          <v-select
            v-model="infos.mois"
            type="text"
            :options="mois"
            label="name"
            placeholder="Sélectionnez le mois"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

    </b-col>
  </b-row>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BFormRadioGroup,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BCardImg,
  // BFormCheckbox,
  // BFormRadio,
  // BForm,
  // BCardText,
  BInputGroupPrepend,
  BInputGroup,
  VBPopover,
  BModal,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import { mapActions } from 'vuex'
import vSelect from 'vue-select'
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ContentLoader } from 'vue-content-loader'
import localstorageService from '@/services/localstorage/localstorage.service'
import TipsModalCreateFromLastRecord from '@/components/TipsModalCreateFromLastRecord.vue'
import utilsService from '@/services/utils/utils.service'
import moment from 'moment'
// imports for validation
// const store = require('store')

// store modules and vuex utilities
import etablissementsStroreModule from '@/store/etablissements'
import paramsStoreModule from '@/store/params'
import utilsStoreModule from '@/store/utils'
import formulaireStoreModule from '@/store/formulaire'

import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BCardHeader,
    BCardBody,
    BFormRadioGroup,
    BCard,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    // BFormCheckbox,
    // BFormRadio,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BCardImg,
    // BCardText,
    // BForm,
    BModal,
    BInputGroupPrepend,
    BInputGroup,
    ContentLoader,
    TipsModalCreateFromLastRecord,
  },
  directives: {
    Ripple,
    'b-popover': VBPopover,
  },
  mixins: [heightTransition], //
  props: {
    etablissementDetails: null,
  },
  setup() {
    const requiredStoreModules = [
      { path: 'etablissements', module: etablissementsStroreModule },
      { path: 'params', module: paramsStoreModule },
      { path: 'utils', module: utilsStoreModule },
      { path: 'formulaire', module: formulaireStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)

    return { requiredStoreModules }
  },
  data() {
    return {
      isFillingForm: false,
      formInput: this.$route.params.data,
      lastRecordedForm: null,
      tipsModalRef: new Date().getTime(),
      showCreateFromLastModal: true,
      currentUserId: localstorageService.getUserId(),
      isCheckingForLastForm: true,
      formType: 'formstatagence',
      email,
      required,
      email,
      confirmed,
      url,
      between,
      alpha,
      integer,
      password,
      min,
      digits,
      alphaDash,
      length,

      annees: [],
      mois: [],

      infos: {
        annee: '',
        mois: '',

      },

      invalideAgrement: false,
      mauvaisEtablissement: false,
      invalideIfu: false,
      auteur: 'Tarinus',

      controleLitFailed: false,
      controleHebergementFailed: false,
    }
  },
  computed: {
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer
      if (rendererType === 'sidebar-left') { return 'layout-content-renderer-left' }
      if (rendererType === 'sidebar-left-detached') { return 'layout-content-renderer-left-detached' }
      return 'layout-content-renderer-default'
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        this.annees = utilsService.getListYears()
        this.mois = utilsService.getListMonths()
       
      },
    },

    'infos.annee': {
      handler(val, old) {
        // console.log('val, old: ', val, old)
        const currentYear = new Date().getFullYear()
        this.infos.mois = ''
        // load current year
        if (val === currentYear) { // load short list
          this.mois = this.removeinvalidMonth(this.mois)
        } else { // load long list
          this.mois = utilsService.getListMonths()
        }
      },
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules)
  },
  mounted() {
   
  },
  methods: {
   
    ...mapActions('formulaire', {
      getLastFormCreatedByUser: 'getLastFormCreatedByUser',
    }),
    ...mapActions('utils', {
      checkIFU: 'checkIFU',
    }),
  
  
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/themes/bordered-layout.scss";
</style>
